import {useEffect, useState} from 'react'
import {isExtension} from 'utils/helper_functions'

const useCheckExtensionInstalled = () => {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false)

  useEffect(() => {
    const checkExtensionInstalled = () => {
      if (isExtension) {setIsExtensionInstalled(true); return }

      // check to see if extension hidden text is in the body of the page
      const body = document.body
      const hiddenText = body.querySelector('extension-hidden')
      if (hiddenText) setIsExtensionInstalled(true)
    }

    checkExtensionInstalled()
  }, [])

  return {isExtensionInstalled}
}

export default useCheckExtensionInstalled