import Head from 'next/head'

const MainSeo = () => {
  // Important: Next head tag only reads direct children. Don't move meta tags into another component.

  const title = 'Tabjay'
  const description = 'Have the links you need for the task you’re doing.'

  return (
    <Head>
      <title>{title}</title>

      <meta name='title' property='og:title' content={title} />
      <meta name="description" property='og:description' content={description} />
      <meta name='type' property='og:type' content='website' />

      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:card' content={description} />

    </Head>
  )
}

export {MainSeo}
