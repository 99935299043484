import {isBrowserEnv} from '@brikit/tabjay-ui-kit'
import {isExtension} from 'utils/helper_functions'


const TabjayExtension = {

  getTabs: function () {
    if (!isBrowserEnv) return null
    const allTabs = JSON.parse(localStorage.getItem('userChromeTabs')) || []
    return filterUnnecessaryTabs(allTabs)
  },

  getHistory: function () {
    if (!isBrowserEnv) return null
    const history = JSON.parse(localStorage.getItem('userChromeHistory')) || null
    return history
  },

  tabUrls: function () {
    return this.getTabs()?.map(tabs => tabs.url)
  },

  historyUrls: function () {
    return this.getHistory()?.map(history => history.url).slice(0, 25)
  },

  allUrls: function () {
    return [...this.tabUrls(), ...this.historyUrls()]
  },

  getCurrentTabInExtension: async () => {
    return isExtension ? await getCurrentTab() : null
  },

  getAllTabsInExtension: async () => {
    return isExtension ? await getAllTabsFromExtensionStorage() : null
  },

  browserData: function () {
    return {
      tabs: this.getTabs,
      history: this.getHistory,
      getCurrentTabInExtension: this.getCurrentTabInExtension, //Extension only
      getAllTabsInExtension: this.getAllTabsInExtension, //Extension only
    }
  },
}

// Filter out tabjay and empty chrome://newtab/ tabs
const filterUnnecessaryTabs = (tabs) => {
  return tabs?.filter(tab => !tab.url.includes(process.env.NEXT_PUBLIC_CLIENT_URL) && !tab.url.includes('chrome://'))
}

// NOTE: This function is called when running in extension mode.
const getAllTabsFromExtensionStorage = async () => {
  const tabs = isBrowserEnv ? await chrome.tabs?.query({}) : null
  return filterUnnecessaryTabs(tabs)
}

// NOTE: This function is called when running in extension mode.
const getCurrentTab = async () => {
  let queryOptions = {active: true, currentWindow: true}
  let tabs = isBrowserEnv ? await chrome.tabs?.query(queryOptions) : null
  return tabs && tabs[0]
}

export default TabjayExtension

/*
  Chrome tab payload:
  {
    "active": false,
    "audible": false,
    "autoDiscardable": true,
    "discarded": false,
    "favIconUrl": "https://calendar.google.com/googlecalendar/images/favicons_2020q4/calendar_26.ico",
    "groupId": -1,
    "height": 971,
    "highlighted": false,
    "id": 1360793772,
    "incognito": false,
    "index": 0,
    "mutedInfo": {
      "muted": false
    },
    "pinned": false,
    "selected": false,
    "status": "complete",
    "title": "Brikit - Calendar - Week of October 23, 2022",
    "url": "https://calendar.google.com/calendar/u/0/r",
    "width": 1680,
    "windowId": 1360793771
  }
*/
